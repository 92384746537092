import { parseSoapResponse } from 'api/GetLookupTables';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

const RepairTypeSelectComponent = (): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const [loading, setLoading] = useState(false);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'RepairTypes');

      const formattedData = test123?.map((item) => item?.['b:RepairTypeId']);

      setData(formattedData);
      setLoading(false);
    };

    fetchData();
  }, []);

  return <FieldInputSelect loading={loading} options={data?.map((item) => ({ label: item, value: item }))} fieldName="RepairTypeId" label="Repair Order Type" placeholder="Repair Type" />;
};

export const RepairTypeSelect = React.memo(RepairTypeSelectComponent);
