import React, { useEffect, useState } from 'react';
import { FieldInputSelect } from './FieldInputSelect';

const parseSoapResponse = (xmlResponse: any) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');

  const inventDimCombinations = xmlDoc.getElementsByTagName('a:ItemId');

  const test = Array.from(inventDimCombinations)
    ?.map((node) => {
      return node.textContent;
    })
    .filter((item): item is string => item !== '');

  return test;
};

const ItemIdSelectComponent = (): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await fetch('https://mdsi-noodle-djdchdane8hwfxgc.eastus2-01.azurewebsites.net/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/xml',
          SOAPAction: 'http://tempuri.org/IRepairModule/GetItemMaster'
        },
        body: `
                    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:tns="http://tempuri.org/">
        <soapenv:Header/>
        <soapenv:Body>
          <tns:GetItemMaster>
            <tns:dataAreaId>MDSI</tns:dataAreaId>
          </tns:GetItemMaster>
        </soapenv:Body>
      </soapenv:Envelope>
                  `
      });
      const textResponse = await resp.text();

      const inventDimCombinations = parseSoapResponse(textResponse);

      if (inventDimCombinations) {
        setData(inventDimCombinations.filter((item): item is string => item !== undefined));
        setLoading(false);
      } else setLoading(false);
    };

    fetchData();
  }, []);

  return <FieldInputSelect loading={loading} options={data?.map((item) => ({ label: item, value: item }))} fieldName="itemId" label="Item ID" placeholder="Item ID" />;
};

export const ItemIdSelect = React.memo(ItemIdSelectComponent);
