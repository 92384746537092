import { parseSoapResponse } from 'api/GetLookupTables';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

const RepairTechSelectComponent = (): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'RepairTechnicians');

      const formattedData = test123?.map((item) => item?.['b:ID']);

      setData(formattedData);
    };

    fetchData();
  }, []);

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="technician" label="Technician" placeholder="Technician" />;
};

export const RepairTechSelect = React.memo(RepairTechSelectComponent);
