import { useAuth0, User } from '@auth0/auth0-react';
import { Space, Table, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { DeleteLineItemButton } from 'components/atoms/DeleteLineItemButton';
import { LineItemModal } from 'components/atoms/LineItemModal';
import { RepairOrderPayload } from 'components/organisms/RepairsTable';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { FC } from 'react';

export type POReceiptLine = {
  itemId: string;
  serialNumber: string;
  unitPrice: number | string;
  sla?: number;
  status: string;
  dateStaged?: string;
  itemStatus: string;
  technician: string;
  salesOrder?: string;
  recId: number;
  repairOrderId: string;
};

type Props = TableProps & {
  repairTypeId?: string;
};

export const LineItemTable: FC<Props> = ({ repairTypeId, ...rest }) => {
  const { values, setValues } = useFormikContext<RepairOrderPayload>();
  const { user } = useAuth0();
  const columns: ColumnsType<POReceiptLine> = [
    {
      title: 'Item ID',
      dataIndex: 'itemId',
      key: 'itemId'
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: 'Repair Stage',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text) => `$${Number(text).toFixed(2)}`,
      align: 'right'
    },
    {
      title: 'SLA',
      dataIndex: 'sla',
      key: 'sla',
      render: (text) => `${text} Days`,
      align: 'right'
    },
    {
      title: 'Date Staged',
      dataIndex: 'dateStaged',
      key: 'dateStaged',
      render: (text) => (text ? moment(text).format('MM-DD-YYYY') : ''),
      align: 'right'
    },
    {
      title: 'Item Status',
      dataIndex: 'itemStatus',
      key: 'itemStatus'
    },
    {
      title: 'Technician',
      dataIndex: 'technician',
      key: 'technician'
    },
    {
      title: 'Sales Order',
      dataIndex: 'salesOrder',
      key: 'salesOrder'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (_, record, idx) => (
        <Space>
          <Tooltip title="Edit">
            <LineItemModal repairTypeId={repairTypeId} idx={idx} record={record} isEdit />
          </Tooltip>
          <DeleteLineItemButton record={record} user={user as User} dataAreaId={'MDSI'} />
        </Space>
      )
    }
  ];

  return (
    <Table
      dataSource={values?.Lines}
      size="small"
      columns={columns}
      rowKey={(record): string => record.lineNumber}
      pagination={{ defaultPageSize: 10, pageSizeOptions: ['10', '20', '50'] }}
      {...(rest as TableProps)}
    />
  );
};
