import { Button, Checkbox, Col, Divider, Drawer, Row, Space, Tabs, Typography } from 'antd';
import { TabsProps } from 'antd/lib';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { LineItemStagedTable } from 'components/molecules/LineItemStagedTable';
import { LineItemTable } from 'components/molecules/LineItemTable';
import { Shipment } from 'models/DockReceipt';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetPurchaseOrderQuery } from 'redux/services/grannyBird/grannyBirdApi';
import { setStagedPoLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { MobileFiltersShipments } from './MobileFiltersShipments';

type Props = {
  record?: Shipment;
  setShipment: React.Dispatch<React.SetStateAction<Shipment | undefined>>;
};

export const ShipmentsDrawer: FC<Props> = ({ record, setShipment }) => {
  const { xs } = useBreakpoint();
  const [key, setKey] = useState('1');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!!record);
  const { stagedPoLines } = useAppSelector((state) => state.app);

  const { data: selectedReceipt, isLoading, isFetching } = useGetPurchaseOrderQuery({ purchaseOrderId: record?.mdsiOrderNumber as string });

  const showDrawer = (): void => {
    setShipment(undefined);
  };

  const onClose = (): void => {
    setKey('1');
    setOpen(false);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Line Items',
      children: (
        <Row style={{ marginTop: 12, width: '100%' }}>
          <Col span={24}>
            <LineItemTable dataSource={selectedReceipt?.lines} loading={{ indicator: <LoaderWithMessage loadingMessage="Loading PO Receipt..." />, spinning: isLoading || isFetching }} />
          </Col>
        </Row>
      )
    },
    {
      key: '2',
      label: `Staged (${stagedPoLines.length})`,
      children: (
        <Row style={{ marginTop: 12, width: '100%' }}>
          <Col span={24}>
            <LineItemStagedTable />
          </Col>
        </Row>
      )
    }
  ];

  const handleOk = (): void => {
    console.log('ok');
  };

  return (
    <>
      {/* <Badge color="green" size="small" count={2}>
        <LottieButton tooltip={!xs ? 'Shipments' : undefined} animationData={shipmentLottie} borderColor="#52CC99" hoverColor="#DDFFEE" onClick={showDrawer} scale={1.5} />
      </Badge> */}
      <Drawer
        destroyOnClose
        afterOpenChange={(open) => {
          if (!open) {
            setShipment(undefined);
            dispatch(setStagedPoLines([]));
          }
        }}
        footer={
          <Row justify={'end'} style={{ width: '100%' }} align={'bottom'}>
            <Space>
              <Button onClick={onClose}>{'Exit'}</Button>
              <Button type={'primary'} onClick={handleOk}>
                Post
              </Button>
            </Space>
          </Row>
        }
        style={{ borderRadius: xs ? 15 : 0 }}
        closeIcon={null}
        onClose={onClose}
        height={'90%'}
        width={xs ? '100%' : '70%'}
        open={open}
        placement={xs ? 'bottom' : 'right'}>
        <ColorCard title={`Line Items: ${record?.mdsiOrderNumber}`} color={toRgba(colors.robinEggBlue, 0.4)} extra={xs && <MobileFiltersShipments />}>
          {!xs && selectedReceipt && (
            <>
              <Space size={xs ? 0 : 10} style={{ width: '100%', marginBottom: 6, textAlign: 'end' }} direction={xs ? 'vertical' : 'horizontal'} align={xs ? 'start' : 'baseline'}>
                <Space style={{ width: '100%' }}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 14 }}>Vendor:</Typography.Text>
                  <Typography.Text style={{ fontSize: 14 }}>{selectedReceipt?.vendorAccount}</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 14 }}>Warehouse:</Typography.Text>
                  <Typography.Text style={{ fontSize: 14 }}>{selectedReceipt?.warehouse}</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 14 }}>Delivery Mode:</Typography.Text>
                  <Typography.Text style={{ fontSize: 14 }}>{selectedReceipt?.deliveryMode}</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 14 }}>Requestor:</Typography.Text>
                  <Typography.Text style={{ fontSize: 14 }}>{selectedReceipt?.requestedBy}</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 14 }}>Orderer:</Typography.Text>
                  <Typography.Text style={{ fontSize: 14 }}>{selectedReceipt?.orderedBy}</Typography.Text>
                </Space>
              </Space>
              <Row align={'middle'} justify={'start'} style={{ marginBottom: 5 }}>
                <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 14, marginRight: 7 }}>Hardware Bundle </Typography.Text>
                <Checkbox />
              </Row>
            </>
          )}
          {xs && (
            <Row>
              <Col span={12}>
                <Space size={4}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 11 }}>Vendor:</Typography.Text>
                  <Typography.Text style={{ fontSize: 11 }}>{selectedReceipt?.vendorAccount}</Typography.Text>
                </Space>
              </Col>
              <Col span={12} style={{ textAlign: 'end' }}>
                <Space size={4}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 11 }}>Warehouse:</Typography.Text>
                  <Typography.Text style={{ fontSize: 11 }}>{selectedReceipt?.warehouse}</Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space size={4}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 11 }}>Delivery:</Typography.Text>
                  <Typography.Text style={{ fontSize: 11 }}>{selectedReceipt?.deliveryMode}</Typography.Text>
                </Space>
              </Col>
              <Col span={12} style={{ textAlign: 'end' }}>
                <Space size={4}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 11 }}>Requestor:</Typography.Text>
                  <Typography.Text style={{ fontSize: 11 }}>{selectedReceipt?.requestedBy}</Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space size={4}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 11 }}>Orderer:</Typography.Text>
                  <Typography.Text style={{ fontSize: 11 }}>{selectedReceipt?.orderedBy}</Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Row align={'middle'} justify={'end'} style={{ marginTop: xs ? 0 : 0, marginBottom: xs ? 0 : 16 }}>
                  <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontSize: 11, marginRight: 7 }}>Hardware Bundle </Typography.Text>
                  <Checkbox />
                </Row>
              </Col>
            </Row>
          )}

          {xs && <Divider type="horizontal" style={{ margin: 12, padding: 0 }} />}
          {!xs && <Divider type="horizontal" style={{ margin: 0, padding: 0, height: 2, width: 2 }} />}
          <Tabs key={key} onChange={(activeKey): void => setKey(activeKey)} activeKey={key} items={items} />
        </ColorCard>
      </Drawer>
    </>
  );
};
