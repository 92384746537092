import { DeleteOutlined } from '@ant-design/icons';
import { Button, List, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { ListProps } from 'antd/lib';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { MobileCardStagedLineItems } from 'components/atoms/MobileCardStagedLineItems';
import { ItemDetails } from 'models/Application';
import { POReceiptModel } from 'models/POReceiptModel';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setStagedPoLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

type Props = (TableProps | ListProps<ItemDetails>) & {
  poReceipt?: POReceiptModel;
  mdsiOrderNumber?: string;
};

export const LineItemStagedTable: FC<Props> = ({ poReceipt, ...rest }) => {
  const { xs } = useBreakpoint();
  const { stagedPoLines } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const columns: ColumnsType<ItemDetails> = [
    {
      title: 'PO Line Number',
      dataIndex: 'poLineNumber',
      key: 'poLineNumber'
    },
    {
      title: 'Item ID',
      dataIndex: 'itemId',
      key: 'itemId',
      render: (text) => text || 'N/A' // Handle undefined values
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse',
      key: 'warehouse',
      render: (text) => text || 'N/A' // Handle undefined values
    },
    {
      title: 'Site',
      dataIndex: 'site',
      key: 'site',
      render: (text) => text || 'N/A' // Handle undefined values
    },
    {
      title: 'Bin',
      dataIndex: 'location',
      key: 'location'
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: 'Direct Delivery',
      dataIndex: 'directDelivery',
      key: 'directDelivery',
      render: (text) => text ?? 'N/A'
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      align: 'right'
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => {
        return <Button size="small" danger icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => dispatch(setStagedPoLines(stagedPoLines.filter((line) => line !== record)))} />;
      }
    }
  ];

  if (xs)
    return (
      <>
        <List
          grid={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4, gutter: [8, 8] }}
          dataSource={stagedPoLines}
          renderItem={(item): JSX.Element => <MobileCardStagedLineItems setKey={console.log} poReceipt={poReceipt as POReceiptModel} record={item} />}
          {...(rest as ListProps<ItemDetails>)}
        />

        {/* <ShipmentsDrawer setShipment={setShipment} record={shipment} /> */}
      </>
    );

  return <Table size="small" columns={columns} dataSource={stagedPoLines} rowKey={(record): string => record.lineNumber} pagination={false} {...(rest as TableProps)} />;
};
