import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { User } from '@auth0/auth0-spa-js';
import { Button, message, Modal, Tooltip } from 'antd';
import { deleteRepairLine } from 'api/DeleteRepairLine';
import { POReceiptLine } from 'components/molecules/LineItemTable';
import { RepairOrderPayload } from 'components/organisms/RepairsTable';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setRefetchLines } from 'redux/slices/appSlice';

type Props = {
  record: POReceiptLine;
  user: User;
  dataAreaId: string;
};

export const DeleteLineItemButton: FC<Props> = ({ record, user, dataAreaId }) => {
  const dispatch = useDispatch();
  const { setValues, values } = useFormikContext<RepairOrderPayload>();
  const handleClick = async (): Promise<void> => {
    if (record.recId === 0) {
      setValues((prev) => ({ ...prev, Lines: prev.Lines.filter((line) => line !== record) }));

      return;
    }
    try {
      await deleteRepairLine({ recId: record.recId, user, dataAreaId });
      message.success('Line item deleted successfully');
      dispatch(setRefetchLines(true));
    } catch (error) {
      console.log(error);
      message.error('Error deleting line item');
    }
  };

  const handleDelete = async (): Promise<void> => {
    Modal.confirm({
      title: 'Are you sure you want to delete this line item?',
      icon: <WarningOutlined />,
      content: 'This will permanently delete the line item.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleClick
    });
  };

  return (
    <Tooltip title="Delete">
      <Button size="small" icon={<DeleteOutlined onClick={handleDelete} />} />
    </Tooltip>
  );
};
