import { parseSoapResponse } from 'api/GetLookupTables';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

const RepairStageSelectComponent = (): JSX.Element => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'RepairStages');

      const formattedData = test123?.map((item) => item?.['b:RepairStageId']);

      setData(formattedData);
    };

    fetchData();
  }, []);

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="status" label="Status (Repair Stage)" />;
};

export const RepairStageSelect = React.memo(RepairStageSelectComponent);
