import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Row, Typography } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { ItemDetails } from 'models/Application';
import { Shipment } from 'models/DockReceipt';
import { POReceiptModel } from 'models/POReceiptModel';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setStagedPoLines } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

type Props = {
  record: ItemDetails;
  drawerRecord?: Shipment;
  poReceipt: POReceiptModel;
  setKey: React.Dispatch<React.SetStateAction<string>>;
};

export const MobileCardStagedLineItems: FC<Props> = ({ record }) => {
  const dispatch = useDispatch();
  const { stagedPoLines } = useAppSelector((state) => state.app);
  const items: DescriptionsProps['items'] = [
    {
      key: '0',
      label: 'Line Number',
      children: record?.poLineNumber ?? 'PO',
      contentStyle: { textAlign: 'end', fontSize: 12 },
      labelStyle: { fontSize: 12 }
    },
    {
      key: '1',
      label: 'Item Id',
      children: record?.itemId ?? '',
      contentStyle: { textAlign: 'end', fontSize: 12 },
      labelStyle: { fontSize: 12 }
    },
    {
      key: '3',
      label: 'Warehouse',
      children: record?.warehouse ?? 'Carson Coyle',
      contentStyle: { textAlign: 'end', fontSize: 12 },
      labelStyle: { fontSize: 12 }
    },
    {
      key: '2',
      label: 'Location',
      children: record?.location ?? 'TEST',
      contentStyle: { textAlign: 'end', fontSize: 12 },
      labelStyle: { fontSize: 12 }
    },
    {
      key: '2',
      label: 'Qty',
      children: record?.qty ?? '',
      contentStyle: { textAlign: 'end', fontSize: 12 },
      labelStyle: { fontSize: 12 }
    },
    {
      key: '4',
      label: 'Serial Number',
      children: record?.serialNumber ?? 'N/A',
      contentStyle: { textAlign: 'end', fontSize: 12 },
      labelStyle: { fontSize: 12 }
    }
  ];

  return (
    <Card
      styles={{ body: { padding: 8 } }}
      title={
        <Row justify={'space-between'} align={'middle'}>
          <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
            {record.itemId}
          </Typography.Title>
          <Button size="small" danger icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => dispatch(setStagedPoLines(stagedPoLines.filter((line) => line !== record)))} />
        </Row>
      }>
      <Descriptions layout="horizontal" bordered size="small" style={{ marginTop: 0 }} items={items} />
    </Card>
  );
};
